import {required,  email} from 'vuelidate/lib/validators';

export default {
    username: {
        email,
        required
    },
    password: {
        required
    },
}