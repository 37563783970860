export default {

    UsernameError() {
        if (!this.$v.username.$anyError) return null;
        else if (!this.$v.username.required) return "please_enter_email";
        else if (this.$v.username.$invalid) return "invalid_field";
        else return null;
    },

    PasswordError() {
        if (!this.$v.password.$anyError) return null;
        else if (!this.$v.password.required) return "please_enter_password";
        else return null;
    },

};