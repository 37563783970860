import UserService from '@/services/user.service';
import NewsService from '@/services/news.service';
import {clearWebmail} from "@/helpers/db";
const service = new UserService();
const newsService = new NewsService();

import Validations from './validation';
import Errors from './Errors';

export default {
    name: 'login',
    components: {},
    props: [],
    data() {
        return {
            loaded: false,
            loading: false,
            formView: "login",
            username: "",
            password: "",
            failedLogin: false,
            failedReset: false,
            failedAccess: false,
            failedAccessMessage: '',
            news:[],
            validationErrors: {
                username: false,
                password: false,
                newPassword: false,
                newPasswordConfirm: false
            },
            reset: {
                email: ''
            },
            resetAction: {
                email: '',
                token: '',
                newPassword: '',
                newPasswordConfirm: ''
            }
        }
    },
    validations: Validations,
    computed: {
        ...Errors,
    },

    beforeMount() {
        if (localStorage.getItem('token') !== null && localStorage.getItem('expires') > (new Date()).getTime().toString()) {
            this.$router.push('/dashboard/index');
            return;
        }
        const _do = this.$route.query.do || 'login';
        if (_do !== 'login' && _do !== 'reset' && _do !== 'reset_action' && _do !== 'reset_requested' && _do !== 'reset_succeeded')
            this.formView = 'login';
        else
            this.formView = _do;
    },

    async mounted() {
        await this.$recaptchaLoaded();

        if (this.formView === 'reset_action') {
            if (!this.$route.query.email || !this.$route.query.token) {
                this.formView = 'reset';
                return;
            } else {
                this.resetAction.email = this.$route.query.email;
                this.resetAction.token = this.$route.query.token;
            }
        }

        await this.newsList();

    },
    methods: {
        validateEmail(email) {
            let validator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return validator.test(String(email).toLowerCase());
        },

        changeView(view) {
            this.formView = view;
            this.$router.push({ query:{do: view}});
        },

        async requestResetPassword() {

            this.loading = true;

            this.failedLogin = false;
            this.failedAccess = false;
            this.failedReset = false;
            this.validationErrors = {
                username: false,
                password: false
            };

            this.resetAction = {
                email: '',
                token: '',
                newPassword: '',
                newPasswordConfirm: ''
            };

            if (this.validateEmail(this.reset.email)) {
                const token = await this.$recaptcha('login');
                const res = await service.requestResetPassword(this.reset.email, token);

                if ( res && !res.error ) {
                    this.changeView('reset_requested')
                } else {
                    this.failedReset = true;
                }

            } else {
                this.validationErrors.username = true;
            }
            this.loading = false;
        },

        async resetPassword() {
            this.loading = true;

            this.failedLogin = false;
            this.failedReset = false;
            this.failedAccess = false;
            this.validationErrors = {
                username: false,
                password: false
            };

            if (!this.resetAction.newPassword || this.resetAction.newPassword.length < 6)
                this.validationErrors.newPassword = true;
            else if (!this.resetAction.newPasswordConfirm || this.resetAction.newPasswordConfirm.length < 6 || this.resetAction.newPasswordConfirm !== this.resetAction.newPassword )
                this.validationErrors.newPasswordConfirm = true;
            else if (!this.validateEmail(this.resetAction.email) || !this.resetAction.token || this.resetAction.token.length !== 10) {
                this.failedReset = true;
            } else {
                const recaptchaToken = await this.$recaptcha('login');
                const res = await service.resetPassword(this.resetAction.email, recaptchaToken, this.resetAction.token, this.resetAction.newPassword, this.resetAction.newPasswordConfirm);

                if ( res && !res.error ) {
                    this.resetAction = {
                        email: '',
                        token: '',
                        newPassword: '',
                        newPasswordConfirm: ''
                    };
                    this.changeView('reset_succeeded')
                } else {
                    this.failedReset = true;
                }
            }
            this.loading = false;
        },

        async doLogin() {
            try {
                await this.$v.$touch();
                if (this.$v.$invalid) return;

                this.loading = true;

                this.failedLogin = false;
                this.failedReset = false;
                this.failedAccess = false;
                this.validationErrors = {
                    username: false,
                    password: false
                };

                if (!this.validateEmail(this.username)) this.validationErrors.username = true;
                else if (this.password.length < 6) this.validationErrors.password = true;
                else {
                    const token = await this.$recaptcha('login');
                    const loginStatus = await this.$store.dispatch("login", {
                        username: this.username,
                        password: this.password,
                        recaptcha: token
                    });

                    if (loginStatus.status) {
                        if (localStorage.getItem('token') !== null) {
                            this.$emit('loggedIn');

                            const mailUser = localStorage.getItem("ez_webmail_user");

                            if ( !mailUser || mailUser !== this.$getUser()._id) {
                                await clearWebmail();
                            }

                            if ( !this.$getActive() && !(this.$getUser().permissions.isRoot || this.$getUser().permissions.isReSeller || this.$getUser().permissions.isSupport) ) {
                                await this.$router.push({name:'ActivationPayment'});
                            } else if (this.$route.params.nextUrl !== null) {
                                await this.$router.push('/');
                            } else {
                                await this.$router.push('/');
                            }
                        } else {
                            this.failedLogin = true;
                        }
                    } else {
                        if (loginStatus.message.includes('No access rule allow this IP')) {
                            this.failedAccess = true;
                            this.failedAccessMessage = loginStatus.message;

                        } else {
                        this.failedLogin = true;
                        }
                    }
                }
                this.loading = false;
            } catch (e) {
                this.failedLogin = true;
                this.loading = false;
            }
        },

        advancePage(direction) {
            const carousel = this.$refs.carousel;
            carousel.advancePage(direction || "forward")
        },

        async newsList() {

            const res = await newsService.list();

            if (res && !res.error) {
                this.news = res.data.newsList;
            }
        },

    }
}
